
// Fonts
@import "~font-awesome/scss/font-awesome.scss";

// Variables
@import "./sass/variables";

// Bootstrap
@import "~bootstrap-sass/assets/stylesheets/bootstrap";


body, label, .checkbox label {
	font-weight: 300;
}

.card-block {
	.panel-heading {
		padding: 5px 10px;
		background-color: #CCC;
		
		.card-title {
			margin: 0;
			padding: 0 7px;
			h4 {
				margin: 0;
			}
		}		
		.card-navbar {
			padding: 0 7px;
			// float: right;
			// width: 4em;
			text-align: right;
			i {
				padding: 0 0.1em;
				cursor: pointer;
			}
			.fa-edit {
				color: $brand-primary;
			}
			.fa-remove {
				color: $brand-danger;
			}
		}
	}
	.panel-body {
		.item-quantity {
			padding: 0 0 0 7px;

		}
		.item-date {
			padding: 0;
		}
	}
}

.card-block-deleting {
	background-color: lighten(#F00, 40%);
	cursor: pointer;
}

.item-filter {
	padding: 1em 0;

	label {
		font-weight: bold;
		background-color: #DDD;
		border-radius: 1em 0 0 1em;
		height: $input-height-base;
		padding: 0.3em 1em;
	}
	div {
		padding-left: 0;
		input {
			border-radius: 0 1em 1em 0;
		}		
	}

}

#add-item-button, .add-button {
	position: fixed;
	top: 1.5em;
	right: 0.2em;
	cursor: pointer;
	color: $brand-success;
}

#containers .container {
	.controls {
		text-align: right;
		i {
			padding: 0 0.1em;
			cursor: pointer;	
			font-size: 2em;		
		}
		.fa-edit {
			color: $brand-primary;
		}
		.fa-remove {
			color: $brand-danger;
		}
	}
}

.icon-menu {
	position: relative;
	display: inline-block;

	.icon-menu-trigger {
		cursor: pointer;
	}

	.icon-menu-menu {
		display: none; 
		position: absolute; 
		top: 20px;
		right: 0;
		border-radius: 0.2em;
		background-color: #CCC;
		border: thin solid black;
		list-style: none;
		margin: 0;
		padding: 0;
		z-index: 9000;
		li {
			border-radius: 0.2em;
			margin: 0.2em;
			background-color: #EEE;
			padding: 0.3em 0.6em;
			cursor: pointer;
			&:hover {
				background-color: #333;
				color: white;
			}	  	
		}	  
	}
}
